<template>
    <div class="versionInfo" v-if="Boolean(translations)">
        <p>
            {{ translations.backend }} (<span
                v-if="loadingBackend"
                class="loading"
                v-html="loadingDots"
            ></span>
            <a :href="backendVersionUrl">{{ formatVersion(backendVersion) }}</a
            >) {{ translations.frontend }} (<span
                v-if="loadingFrontend"
                class="loading"
                v-html="loadingDots"
            ></span>
            <a :href="frontendVersionUrl">{{ formatVersion(frontendVersion) }}</a
            >).
        </p>
    </div>
</template>

<script>
export default {
    name: 'VersionInfo',
    props: {
        translations: Object,
    },
    data() {
        return {
            backendVersion: '',
            frontendVersion: '',
            loadingBackend: true,
            loadingFrontend: true,
        }
    },
    computed: {
        loadingDots() {
            return '<span>.</span><span>.</span><span>.</span>'
        },
        frontendVersionUrl() {
            return `https://gitlab.com/pleio/pdfchecker/-/tags/${this.frontendVersion}`
        },
        backendVersionUrl() {
            return `https://github.com/veraPDF/verapdf-webapp-server/releases/tag/${this.backendVersion}`
        },
    },
    methods: {
        formatVersion(version) {
            return version
        },
    },
    created() {
        const frontendUrl = 'https://gitlab.com/api/v4/projects/pleio%2Fpdfchecker/repository/tags'
        const backendUrl = 'https://api.github.com/repos/veraPDF/verapdf-webapp-server/tags'

        fetch(frontendUrl)
            .then(response => response.json())
            .then(data => {
                if (data && data?.length > 0) {
                    this.frontendVersion = data[0].name
                }
            })
            .catch(error => {
                console.error('Failed to fetch frontend version from ', frontendUrl, error)
            })
            .finally(() => {
                this.loadingFrontend = false
            })

        fetch(backendUrl)
            .then(response => response.json())
            .then(data => {
                if (data && data?.length > 0) {
                    this.backendVersion = data[0].name
                }
            })
            .catch(error => {
                console.error('Failed to fetch backend version from ', backendUrl, error)
            })
            .finally(() => {
                this.loadingBackend = false
            })
    },
}
</script>

<style scoped>
.versionInfo {
    margin-top: 1rem;
}
.loading span {
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}
.loading span:nth-child(2) {
    animation-delay: 0.2s;
}
.loading span:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes blink {
    0% {
        opacity: 0.2;
    }

    20% {
        opacity: 1;
    }

    100% {
        opacity: 0.2;
    }
}
</style>
