<template>
    <div class="about">
        <div v-if="translationsError">
            <h1 class="error">{{ $t('error.couldNotGetTranslations') }}</h1>
        </div>
        <div v-else>
            <div class="back-button">
                <router-link to="/" class="button large tertiary">
                    <svg
                        width="16px"
                        height="16px"
                        viewBox="0 0 16 16"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                    >
                        <g
                            id="Page-1"
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                        >
                            <g
                                id="arrow_back_black_24dp"
                                transform="translate(-4.000000, -4.000000)"
                                fill="currentColor"
                                fill-rule="nonzero"
                            >
                                <polygon
                                    id="Path"
                                    points="20 11 7.83 11 13.42 5.41 12 4 4 12 12 20 13.41 18.59 7.83 13 20 13"
                                ></polygon>
                            </g>
                        </g>
                    </svg>
                    {{ $t('common.backToStart') }}
                </router-link>
            </div>

            <span class="hide-visually" id="opens-in-new-window">{{
                $t('common.opensInNewWindow')
            }}</span>
            <h1>{{ translations.title }}</h1>
            <RichText :nodes="translations.content" />
            <VersionInfo :translations="translations.versionInfo" />
        </div>
    </div>
</template>

<script>
import VersionInfo from '../components/VersionInfo.vue'
import RichText from '../components/RichText/RichText.vue'

export default {
    name: 'About',
    components: {
        VersionInfo,
        RichText,
    },
    data() {
        return {
            translations: {},
            translationsError: false,
        }
    },
    created() {
        this.fetchTranslations()
    },
    watch: {
        '$i18n.locale': {
            handler: function() {
                this.fetchTranslations()
            },
        },
    },
    methods: {
        async fetchTranslations() {
            /* Below code is temporary. When the CMS is permanently available, the code below can be removed */
            if (config.VUE_APP_CMS_BASE_URL === undefined) {
                this.translations = {
                    title: this.$i18n.t('about.intro'),
                    content: [
                        {
                            type: 'h2',
                            children: [
                                {
                                    text: this.$i18n.t('about.whatDoesItDo'),
                                },
                            ],
                        },
                        {
                            children: [
                                {
                                    text: '',
                                },
                            ],
                        },
                        {
                            children: [
                                {
                                    text: `${this.$i18n.t('about.testPDF')} `,
                                },
                                {
                                    url: 'https://www.w3.org/Translations/WCAG21-nl/',
                                    type: 'link',
                                    children: [
                                        {
                                            text: this.$i18n.t('about.WCAGCriteria'),
                                        },
                                    ],
                                },
                                {
                                    text: ` ${this.$i18n.t('about.andMost')} `,
                                },
                                {
                                    url: 'https://www.iso.org/standard/64599.html',
                                    type: 'link',
                                    children: [
                                        {
                                            text: this.$i18n.t('about.PDFAUEssentials'),
                                        },
                                    ],
                                },
                                {
                                    text: '.',
                                },
                            ],
                        },
                        {
                            children: [
                                {
                                    text: '',
                                },
                            ],
                        },
                        {
                            children: [
                                {
                                    text: `${this.$i18n.t('about.appHelps')}. ${this.$i18n.t(
                                        'about.ifNoErrors'
                                    )}`,
                                },
                            ],
                        },
                        {
                            children: [
                                {
                                    text: '',
                                },
                            ],
                        },
                        {
                            children: [
                                {
                                    bold: true,
                                    text: this.$i18n.t('about.doNotUse'),
                                },
                            ],
                        },
                        {
                            children: [
                                {
                                    bold: true,
                                    text: '',
                                },
                            ],
                        },
                        {
                            children: [
                                {
                                    text: this.$i18n.t('about.noCheckerGuarantee'),
                                },
                            ],
                        },
                        {
                            children: [
                                {
                                    text: '',
                                },
                            ],
                        },
                        {
                            children: [
                                {
                                    text: `${this.$i18n.t('about.PDFCheckerBeta')} `,
                                },
                                {
                                    url:
                                        'https://digitaaltoegankelijk.pleio.nl/groups/view/5d73678c-90cc-4547-9c7e-10733c86b867/pdfcheckernl',
                                    type: 'link',
                                    children: [
                                        {
                                            text: this.$i18n.t('about.askQuestion'),
                                        },
                                    ],
                                },
                                {
                                    text: '.',
                                },
                            ],
                        },
                        {
                            children: [
                                {
                                    text: '',
                                },
                            ],
                        },
                        {
                            type: 'h2',
                            children: [
                                {
                                    text: this.$i18n.t('about.whyThisApp'),
                                },
                            ],
                        },
                        {
                            children: [
                                {
                                    text: '',
                                },
                            ],
                        },
                        {
                            children: [
                                {
                                    text: `${this.$i18n.t('about.differentVendors')} `,
                                },
                                {
                                    url:
                                        'https://www.access-for-all.ch/en/pdf-accessibility-checker.html',
                                    type: 'link',
                                    children: [
                                        {
                                            text: this.$i18n.t('about.PAC3'),
                                        },
                                    ],
                                },
                                {
                                    text: ` ${this.$i18n.t('about.orOnline')} `,
                                },
                                {
                                    url: 'http://checkers.eiii.eu/en/pdfcheck/',
                                    type: 'link',
                                    children: [
                                        {
                                            text: this.$i18n.t('about.TingtunChecker'),
                                        },
                                    ],
                                },
                                {
                                    text: `. ${this.$i18n.t('about.commercialProducts')} `,
                                },
                            ],
                        },
                        {
                            children: [
                                {
                                    text: '',
                                },
                            ],
                        },
                        {
                            children: [
                                {
                                    text: this.$i18n.t('about.ourAppTests'),
                                },
                            ],
                        },
                        {
                            children: [
                                {
                                    text: '',
                                },
                            ],
                        },
                        {
                            children: [
                                {
                                    text: this.$i18n.t('about.OpenSource'),
                                },
                            ],
                        },
                        {
                            children: [
                                {
                                    text: '',
                                },
                            ],
                        },
                        {
                            type: 'h2',
                            children: [
                                {
                                    text: this.$i18n.t('about.whoOwnsThisApp'),
                                },
                            ],
                        },
                        {
                            children: [
                                {
                                    text: '',
                                },
                            ],
                        },
                        {
                            children: [
                                {
                                    text: `${this.$i18n.t('about.thisAppBuiltBy')} `,
                                },
                                {
                                    url: 'https://pleio.nl/',
                                    type: 'link',
                                    children: [
                                        {
                                            text: 'Pleio',
                                        },
                                    ],
                                },
                                {
                                    text: ` ${this.$i18n.t('about.inCollaborationWith')} `,
                                },
                                {
                                    url: 'https://duallab.com/',
                                    type: 'link',
                                    children: [
                                        {
                                            text: 'Dual Lab',
                                        },
                                    ],
                                },
                                {
                                    text: ` (PDF expertise) ${this.$i18n.t('about.and')} `,
                                },
                                {
                                    url: 'https://forumstandaardisatie.nl/',
                                    type: 'link',
                                    children: [
                                        {
                                            text: 'Forum Standaardisatie',
                                        },
                                    ],
                                },
                                {
                                    text: `. ${this.$i18n.t('about.fundingReceivedFrom')} `,
                                },
                                {
                                    url:
                                        'https://www.digitaleoverheid.nl/overzicht-van-alle-onderwerpen/innovatie/innovatiebudget/',
                                    type: 'link',
                                    children: [
                                        {
                                            text: this.$i18n.t('about.InnovationBudget'),
                                        },
                                    ],
                                },
                                {
                                    text: ` ${this.$i18n.t('about.fromMinistryOfInterior')} `,
                                },
                            ],
                        },
                        {
                            children: [
                                {
                                    text: '',
                                },
                            ],
                        },
                        {
                            type: 'h2',
                            children: [
                                {
                                    text: this.$i18n.t('about.termsAndConditions'),
                                },
                            ],
                        },
                        {
                            children: [
                                {
                                    text: '',
                                },
                            ],
                        },
                        {
                            children: [
                                {
                                    text: this.$i18n.t('about.thisAppProvidedFree'),
                                },
                            ],
                        },
                        {
                            children: [
                                {
                                    text: '',
                                },
                            ],
                        },
                        {
                            children: [
                                {
                                    text: this.$i18n.t('about.weTakePrivacySeriously'),
                                },
                            ],
                        },
                        {
                            children: [
                                {
                                    text: '',
                                },
                            ],
                        },
                        {
                            children: [
                                {
                                    bold: true,
                                    text: this.$i18n.t('about.doNotUsePublicCheckers'),
                                },
                            ],
                        },
                        {
                            children: [
                                {
                                    text: '',
                                },
                            ],
                        },
                        {
                            children: [
                                {
                                    text: this.$i18n.t('about.PleioAndForumNotLiable'),
                                },
                            ],
                        },
                        {
                            children: [
                                {
                                    text: '',
                                },
                            ],
                        },
                        {
                            type: 'h2',
                            children: [
                                {
                                    text: this.$i18n.t('about.forDevelopers'),
                                },
                            ],
                        },
                        {
                            children: [
                                {
                                    text: '',
                                },
                            ],
                        },
                        {
                            children: [
                                {
                                    text: `${this.$i18n.t('about.codeBehindAppOpen')} `,
                                },
                                {
                                    url: 'https://github.com/veraPDF/verapdf-webapp-server',
                                    type: 'link',
                                    children: [
                                        {
                                            text: this.$i18n.t('about.veraGithubRepo'),
                                        },
                                    ],
                                },
                                {
                                    text: '.',
                                },
                            ],
                        },
                        {
                            children: [
                                {
                                    text: '',
                                },
                            ],
                        },
                        {
                            children: [
                                {
                                    text: `${this.$i18n.t('about.ifYouHaveFeedback')} `,
                                },
                            ],
                        },
                    ],
                    versionInfo: {
                        backend: 'De pdf-checker is opgebouwd uit een backend gedeelte',
                        frontend: 'en de frontend',
                    },
                }
                return
            }
            /* Above code is temporary. When the CMS is permanently available, the code above can be removed and the code below can be uncommented. */
            /*
            if (config.VUE_APP_CMS_BASE_URL === undefined) {
                this.translationsError = true
            }
            */

            try {
                const aboutPageTranslationsResponse = await fetch(
                    `${config.VUE_APP_CMS_BASE_URL}/api/globals/about-page?locale=${this.$i18n
                        .locale || 'nl'}`
                )

                const aboutPageTranslations = await aboutPageTranslationsResponse.json()

                this.translations = aboutPageTranslations
            } catch (error) {
                this.translationsError = true
            }
        },
    },
}
</script>

<style scoped>
.about {
    margin: 0 auto;
    padding: var(--size-large) var(--size-small);
    font-size: var(--font-size-large);
    max-width: 685px;
}

.error {
    color: var(--color-alert);
    max-width: 600px;
    margin: calc(20vh - var(--size-large)) auto 0 auto;
}

.back-button {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
}

.email-button {
    display: flex;
    justify-content: center;
    margin: 24px 0;
}

h1 {
    font-size: var(--font-size-title-large);
    font-weight: var(--font-weight-bold);
    color: var(--color-primary);
    margin: 0 0 32px;
    text-align: center;
}

h2 {
    font-size: var(--font-size-title-normal);
    font-weight: var(--font-weight-bold);
    margin: 40px 0 16px;
}

h1 + h2 {
    margin-top: 0;
}

p {
    margin: 0 0 16px;
}
</style>
